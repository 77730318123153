<template>
  <div class="form-check form-switch">
    <input class="form-check-input" :class="inputClass" type="checkbox" :name="name" :id="id" :checked="isChecked" ref="input" @change="onChange" />
    <label class="form-check-label" :class="labelClass" :for="id">
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  name: "UISwitch",
  data() {
    return {
      isChecked : Boolean(this.checked)
    }
  },
  props: {
    name: String,
    id: String,
    checked: Boolean,
    labelClass: String,
    inputClass: String,
  },
  emits: ["input"],
  methods: {
    onChange() {
      this.isChecked = this.$refs.input.checked;
      this.$emit("input",this.isChecked);
    }
  }
};
</script>
